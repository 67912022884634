<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <detail-sanitaire
      :animal="animal"
      :create-by="createBy"
      :create-at="createAt"
      v-if="hasAnamnese"
    >
      <template slot="header">
        <h4>{{ motif }}</h4>
      </template>
      <template slot="content">
        <div
          class="row info"
          style="padding-top: 40px"
        >
          <div class="col-4">
            <span
              class="badge"
              :class="anamnese.vaccine? 'badge-success':'badge-danger'"
            >Vacciné</span>
          </div>
          <div class="col-4">
            <span
              class="badge"
              :class="anamnese.vermifuge? 'badge-success':'badge-danger'"
            >Vermifugé</span>
          </div>
          <div class="col-4">
            <span
              class="badge"
              :class="anamnese.traitement? 'badge-success':'badge-danger'"
            >Sous traitement</span>
          </div>
        </div>
           
        <div class="row info">
          <div class="col-12">
            <h6> Symptôme </h6>
            <p>{{ anamnese.symptome }}</p>
          </div>
        </div>
        <div class="row info">
          <div class="col-4">
            <h6> Durée des symptômes </h6>
            <p> {{ anamnese.duration }} </p>
          </div>
          <div class="col-4">
            <h6> Mode de vie </h6>
            <p> {{ mode }} </p>
          </div>
          <div class="col-4">
            <h6> Alimentation </h6>
            <!-- <ul>
              <li
                v-for="(a, i) in als"
                :key="i"
              >
                {{ a }}
              </li>
            </ul> -->
          </div>
        </div>
        <div class="row info">
          <div class="col-12">
            <h6>Antecedant médicaux</h6>
            {{ anamnese.antecedant }}
          </div>
        </div>
      </template>
    </detail-sanitaire>
  </div>
</template>

<script>
import navbar from '../../../components/navbar.vue';
import DetailSanitaire from '../../../components/sanitaire/detailSanitaire.vue'
// import Animal from '../../../components/identification/animal/animalVerticalDetail.vue'
import { mapGetters } from 'vuex';
export default {
    components: {navbar, DetailSanitaire},
    data(){
        return {
            navbarItems: [
                { libelle: 'Sanitaire' },
                { libelle: 'Anamneses', route: 'anamnese' },
                { libelle: 'Détail' }
            ],
            pageIcon: 'la-hospital',
            pageTitle: 'Détail',
            pageDescription: 'Anamnese détail',
            anamnese: null
        }
    },
    watch: {
        anamneses: {
            handler(){
                this.load()
            },
            deep: true
        }
    },
    mounted(){
        this.load()
    },  
    methods: {
        load(){
            if(this.$route.params.uid !== null){
                this.anamnese =  this.anamneses.find(item => item.uid === this.$route.params.uid)
            }else{
                this.anamnese =  null
            }
            
        }
    },
    computed: {
        ...mapGetters({
            anamneses: 'sanitaire/anamneses',
            motifs: 'sanitaire/motifConsultations',
            modes: 'sanitaire/modeVies',
            aliments: 'identification/aliments'
        }),
        hasAnamnese() { return this.anamnese !== null && this.anamnese !== undefined},
        animal(){
            if(this.hasAnamnese) return this.anamnese.animal
            return null
        },
        createBy(){
            return (this.hasAnamnese)? this.anamnese.createBy:null
        },
        createAt(){
            return (this.hasAnamnese)? this.anamnese.createdAt:null
        },
        motif(){
            if(this.hasAnamnese) {
                let m = this.motifs.find(item => item.uid === this.anamnese.motif)
                if(m) return m.libelle
            }
            return '-'
        },
        mode(){
            if(this.hasAnamnese){
                let m = this.modes.find(item => item.uid === this.anamnese.modeVie)
                if(m) return m.libelle
            }
            return '-'
        },
        als(){
          let result = []
          if(this.hasAnamnese){
            console.log(this.anamnese.alimentation)
            // JSON.parse(this.anamnese.alimentation).forEach(item => {
            //   let al = this.aliments.find(i => i.uid === item)
            //   if(al) result.push(al.libelle)
            // })
          }
          return result
        }

    }

}
</script>

<style>

</style>